import React from 'react'
import type { Category } from '@centrito/api/models/public'
import PATHS from '@centrito/common/paths'
import { ScrollView } from '@centrito/ui/src'
import { CircleFeedButton } from '@centrito/ui/src/components/button/CircleFeedButton'

interface ProductListCategoriesSliderProps {
  categories: Category[]
  listId: string
}

export const ProductListCategoriesSlider: React.FC<ProductListCategoriesSliderProps> = ({
  categories,
  listId,
}) => {
  return (
    <ScrollView height={85} showsHorizontalScrollIndicator={false} horizontal>
      {categories.map((category) => (
        <CircleFeedButton
          key={`category-browser--${category.nodes}`}
          href={{
            pathname: PATHS.Products.List,
            query: {
              listId: listId,
              selectedCategory: category.nodes,
            },
          }}
          imageSrc={category.assets.tab || ''}
          imageAlt={category.nodes}
          label={category.nodesNamed.split('-').pop() || ''}
          containerHeight={60}
        />
      ))}
    </ScrollView>
  )
}
