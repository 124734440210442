import React, { useEffect, useRef, useState } from 'react'
import { View } from '@centrito/ui/src'

interface InfiniteScrollProps {
  loadMore: () => void
  hasMore: boolean
}

const InfiniteScroll: React.FC<InfiniteScrollProps> = ({ loadMore, hasMore }) => {
  const loader = useRef<HTMLDivElement | null>(null)
  const [isInitialFetch, setIsInitialFetch] = useState(true)

  useEffect(() => {
    setIsInitialFetch(true)
  }, [])

  useEffect(() => {
    const handleScroll = (entries: IntersectionObserverEntry[]): void => {
      const target = entries[0]
      if (target.isIntersecting && hasMore) {
        if (!isInitialFetch) {
          loadMore()
        } else {
          setIsInitialFetch(false)
        }
      }
    }

    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    }

    const observer = new IntersectionObserver(handleScroll, options)
    if (loader.current) {
      observer.observe(loader.current)
    }

    return () => {
      if (loader.current) {
        observer.unobserve(loader.current)
      }
    }
  }, [hasMore, isInitialFetch, loadMore])

  return (
    <div className="infinite-scroll-container">
      <View></View>
      <div ref={loader} />
    </div>
  )
}

export default InfiniteScroll
