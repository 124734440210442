import React, { useEffect, useRef } from 'react'
import type { ProductExtendedPublicComposite } from '@centrito/api/nest/catalog/products/domain/composites/product-extended.composite'
import FilterBar from '@centrito/app/components/Feed/FilterBar'
import { Footer } from '@centrito/app/components/Feed/ProductGroup/Footer'
import { ProductDisplay } from '@centrito/app/components/ProductDisplay/ProductDisplay'
import posthogClient from '@centrito/app/utils/services/analytics/posthog'
import type { FeedType } from '@centrito/app/utils/services/analytics/posthog/utils/FeedType'
import BaseGrid from '@centrito/ui/src/components/BaseGrid'
import InfiniteScroll from './InfiniteScroll'

const InfiniteScrollMemo = React.memo(InfiniteScroll)
const FilterBarMemo = React.memo(FilterBar)

interface ImpressionTrackingProps {
  productId: string
  productName: string
  position: number
  feedType: FeedType
}

const useImpressionTracking = ({
  productId,
  productName,
  position,
  feedType,
}: ImpressionTrackingProps): React.RefObject<HTMLDivElement> => {
  const productRef = useRef<HTMLDivElement>(null)
  const hasBeenViewed = useRef(false)

  useEffect(() => {
    const currentProduct = productRef.current
    if (!currentProduct) return

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !hasBeenViewed.current) {
          posthogClient.captureProductImpression(feedType, productId, productName, position)
          hasBeenViewed.current = true
        }
      },
      { threshold: 1 }, // 100% of the item is visible
    )

    observer.observe(currentProduct)

    return (): void => {
      if (currentProduct) {
        observer.unobserve(currentProduct)
      }
    }
  }, [productId, productName, position, feedType])

  return productRef
}

interface ProductWithImpressionProps {
  product: ProductExtendedPublicComposite
  index: number
  feedType: FeedType
}

const ProductWithImpressionComponent: React.FC<ProductWithImpressionProps> = ({
  product,
  index,
  feedType,
}) => {
  const ref = useImpressionTracking({
    productId: product.product.id,
    productName: product.product.name,
    position: index,
    feedType,
  })

  return (
    <div ref={ref}>
      <ProductDisplay
        key={'product-display-feed-' + product.product.id}
        product={product}
        feedType={feedType}
        index={index}
      />
    </div>
  )
}

const ProductWithImpression = React.memo(ProductWithImpressionComponent)

export interface FeedProductsGroupProps {
  products: ProductExtendedPublicComposite[]
  loadMoreProducts: () => void
  hasMoreProducts: boolean
  feedType: FeedType
  isShowingFilters?: boolean
  productsCount?: number
}

const FeedProductsGroup: React.FC<FeedProductsGroupProps> = ({
  products,
  loadMoreProducts,
  hasMoreProducts,
  feedType,
  isShowingFilters = true,
  productsCount = undefined,
}) => {
  const data = [
    ...products.map((product, index) => (
      <ProductWithImpression
        key={'product-with-impression-' + index}
        product={product}
        index={index}
        feedType={feedType}
      />
    )),
    <InfiniteScrollMemo
      key={products.length}
      loadMore={loadMoreProducts}
      hasMore={hasMoreProducts}
    />,
  ]

  const generateChunks = (head: JSX.Element[], tail: JSX.Element[][]): JSX.Element[][] => {
    if (head.length > 0) {
      const newHead = head.slice(2)
      const newTail = [...tail, head.slice(0, 2)]

      return generateChunks(newHead, newTail)
    }
    return tail
  }

  const chunks = generateChunks(data, [])

  return (
    <React.Fragment>
      {isShowingFilters && <FilterBarMemo productsCount={productsCount} />}
      <BaseGrid stackProps={{ alignItems: 'center' }}>
        {chunks.map((chunk, chunkIndex) => (
          <BaseGrid.Row key={'product-feed-grid-' + chunkIndex}>
            {chunk.map((item, itemIndex) => (
              <BaseGrid.Column
                stackProps={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                key={chunkIndex * 2 + itemIndex}
              >
                {item}
              </BaseGrid.Column>
            ))}
          </BaseGrid.Row>
        ))}
      </BaseGrid>
      {<Footer hasMoreProducts={hasMoreProducts} numProducts={chunks.length} />}
    </React.Fragment>
  )
}

export default FeedProductsGroup
