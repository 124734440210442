import * as React from 'react'
import type { SVGProps } from 'react'

const SmillingSamSamLogo = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={35} height={35} fill="none" {...props}>
    <g clipPath="url(#a)">
      <path
        fill="#4747F5"
        d="M28.796 0H6.154A6.154 6.154 0 0 0 0 6.154v22.642a6.154 6.154 0 0 0 6.154 6.154h22.642a6.154 6.154 0 0 0 6.154-6.154V6.154A6.154 6.154 0 0 0 28.796 0Z"
      />
      <path
        fill="#FFF4EB"
        d="M28.42 15.994v5.805l-.004.132a4.507 4.507 0 0 1-7.689 3 .007.007 0 0 0-.009 0 4.495 4.495 0 0 1-3.13 1.264 4.548 4.548 0 0 1-3.195-1.327.007.007 0 0 0-.01 0 4.507 4.507 0 0 1-7.75-2.937h-.01v-5.857c0-4.208 3.518-7.604 7.719-7.362a7.28 7.28 0 0 1 1.93.377 4.316 4.316 0 0 0 2.746-.085 7.293 7.293 0 0 1 9.386 6.988h.014l.001.002Z"
      />
      <path
        fill="#000"
        d="M21.091 17.182a2.311 2.311 0 0 1-.644-1.282c-.044-.269.028-.406.245-.44.218-.035.326.057.384.33.174.828.71 1.274 1.444 1.204.71-.069 1.236-.515 1.427-1.213.013-.047.03-.093.045-.142.134-.13.29-.18.452-.08.172.107.154.288.112.452a2.039 2.039 0 0 1-.836 1.204c.21.097.413.104.606.147.263.057.396.216.352.418-.038.177-.229.277-.478.218a8.757 8.757 0 0 0-2.013-.228 8.451 8.451 0 0 0-1.754.174c-.183.038-.335.007-.425-.166-.101-.193.018-.407.259-.457.257-.053.518-.088.823-.139h.001ZM16.21 17.324c.187.022.299.112.356.3.098.32.314.494.554.484.247-.01.45-.195.549-.5.112-.346.18-.386.53-.303.101.09.137.207.124.342-.055.554-.555 1.046-1.108 1.092-.586.049-1.096-.313-1.258-.888-.068-.238-.036-.428.253-.527ZM25.819 17.588c-.127-.375-.033-.515.317-.518.431-.005.857.058 1.281.124.2.03.281.168.257.354-.022.172-.147.26-.313.238-.444-.06-.888-.12-1.338-.116-.067 0-.133-.052-.204-.082ZM27.297 15.534c.005.16-.077.269-.22.311-.36.106-.711.233-1.058.374-.185.074-.345.062-.44-.136-.077-.163.012-.327.226-.425.35-.161.713-.282 1.08-.395.221-.067.404.061.412.272ZM11.414 17.526c.742-.005 1.493-.054 2.212.13.151-.232.24-.509.24-.806v-1.08a1.481 1.481 0 1 0-2.963 0v1.08c0 .278.079.538.213.761a.546.546 0 0 1 .299-.085Z"
      />
      <path
        fill="#FFEFE7"
        d="M12.315 15.508a.454.454 0 0 0-.908 0v.379a.454.454 0 0 0 .908 0v-.38Z"
      />
      <path
        fill="#000"
        d="M11.076 17.314c-.306.05-.567.086-.824.139-.24.05-.36.264-.26.457.09.173.243.204.426.166a8.447 8.447 0 0 1 1.755-.174c.677.002 1.35.07 2.012.228.25.059.44-.04.479-.218.044-.202-.09-.361-.352-.418-.194-.043-.397-.05-.606-.147a8.444 8.444 0 0 0-2.63-.034v.001Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h34.95v34.95H0z" />
      </clipPath>
    </defs>
  </svg>
)
export default SmillingSamSamLogo
